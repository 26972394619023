import { render, staticRenderFns } from "./TitleUnderline.vue?vue&type=template&id=06fa5edb&scoped=true&"
import script from "./TitleUnderline.vue?vue&type=script&lang=js&"
export * from "./TitleUnderline.vue?vue&type=script&lang=js&"
import style0 from "./TitleUnderline.vue?vue&type=style&index=0&id=06fa5edb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fa5edb",
  null
  
)

export default component.exports