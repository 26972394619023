<template>
  <span class="title-underline">
    <span>{{ title }}</span>
  </span>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.title-underline {
  font-size: 24px;
  color: #39425c;
  font-weight: bold;
  position: relative;
  span {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #2593ff;
    position: absolute;
    bottom: -3px;
    left: 0;
    z-index: 1;
  }
  &::after {
    content: "";
    display: block;
    width: calc(100% - 15px);
    height: 10px;
    border-radius: 5px;
    background-color: #2593ff;
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 1;
  }
}
</style>
